import moment from "moment";
import logo from "@/assets/logo.png";
import gptw from "@/assets/GPTW_2023-2024.png";
import ona from "@/assets/ONA_Nivel_3.png";

class UtilsPDF {
    async selectDirectory() {
        // Solicita ao usuário para escolher uma pasta
        try {
            const directoryHandle = await window.showDirectoryPicker();
            return directoryHandle;
        } catch (error) {
            console.error('Erro ao selecionar a pasta:', error);
            throw error;
        }
    }

    async saveToDirectory(directoryHandle, doc, filename, toast) {
        try {
            const blob = doc.output('blob');

            // Verifica se o nome do arquivo é válido
            if (!filename || filename.trim() === '') {
                throw new Error('Nome do arquivo inválido');
            }

            // Substitui caracteres inválidos no nome do arquivo
            filename = filename.replace(/[<>:"/\\|?*]+/g, '-');

            // Tenta criar um novo arquivo na pasta selecionada
            const fileHandle = await directoryHandle.getFileHandle(filename, { create: true });

            const writableStream = await fileHandle.createWritable();
            await writableStream.write(blob);
            await writableStream.close();

            if (toast) {
                toast.success('Arquivo salvo com sucesso!');
            }
        } catch (error) {
            if (toast) {
                toast.error(`Erro ao salvar o arquivo: ${error.message}`);
            }
        }
    }

    async savePDF(doc, filename, toast) {
        try {
            const blob = doc.output('blob');

            const opts = {
                suggestedName: filename,
                types: [{
                    description: 'PDF Files',
                    accept: { 'application/pdf': ['.pdf'] },
                }],
            };

            const handle = await window.showSaveFilePicker(opts);
            const writableStream = await handle.createWritable();
            await writableStream.write(blob);
            await writableStream.close();
            if (toast) {
                toast.success('Arquivo salvo com sucesso!');
            }
        } catch (error) {
            if (toast) {
                toast.error(`Erro ao salvar o arquivo: ${error.message}`);
            }
        }
    }

    addSignatureBlock(doc, data, lineHeight, xPosition, yPosition) {
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text("De Acordo: ___________________________________", xPosition, yPosition);
        yPosition += lineHeight;
        doc.text("Data: ____ / ____ / ______", xPosition, yPosition);
        yPosition += lineHeight;
        doc.text("Nome: _______________________________________", xPosition, yPosition);
        yPosition += lineHeight;
        doc.text("CPF ou RG: __________________________________", xPosition, yPosition);
        yPosition += lineHeight;
        doc.text(`Contratante: ${data}`, xPosition, yPosition);
        yPosition += lineHeight;
    }

    summary(doc, yPosition, lineHeight, dailyAverage, amount, text) {
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`------ RESUMO ------`, 200, yPosition, { align: "right" });
        yPosition += lineHeight;
        doc.text(`Média diária : ${dailyAverage ? this.formatToBRL(dailyAverage) : 'R$ 0,00'}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight;
        doc.text(`Valor Total ${text}: ${amount ? this.formatToBRL(amount) : 'R$ 0,00'}`, 200, yPosition, { align: "right" });
    }

    async saveGeneratedPDF(handle, doc, toast) {
        try {
            const blob = doc.output('blob');

            const writableStream = await handle.createWritable();
            await writableStream.write(blob);
            await writableStream.close();
            if (toast) {
                toast.success('Arquivo salvo com sucesso!');
            }
        } catch (error) {
            if (toast) {
                toast.error(`Erro ao salvar o arquivo: ${error.message}`);
            }
        }
    }

    addPageNumbers(doc) {
        const pageCount = doc.getNumberOfPages();
        const endereco = "Rua Projetada Interna, Nº 4, Araçagy, São José de Ribamar / MA, CEP: 65.125-253. Tel: (98) 3236-1737. www.saoluishomecare.com.br";

        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(8);
            doc.setFont("helvetica", "normal");

            // Adicionando a linha azul
            doc.setDrawColor(57, 106, 159); // Uma cor azul
            doc.setLineWidth(1); // Define a largura da linha
            doc.line(5, 285, 205, 285); // Desenha a linha (x1, y1, x2, y2)

            // Adicionando o endereço logo abaixo da linha
            doc.text(endereco, 10, 290); // Define uma posição x menor para o alinhamento à esquerda

            // Mantém a paginação à direita
            doc.text(`Página ${i} de ${pageCount}`, 200, 290, { align: "right" });
        }
    }
    addPageNumbersLMO(doc) {
        const pageCount = doc.getNumberOfPages();
        const endereco = "Estrada do Aracagy, SN, Araçagy, São José de Ribamar / MA, CEP: 65.110-000.";

        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(8);
            doc.setFont("helvetica", "normal");

            // Adicionando a linha azul
            doc.setDrawColor(57, 106, 159); // Uma cor azul
            doc.setLineWidth(1); // Define a largura da linha
            doc.line(5, 285, 205, 285); // Desenha a linha (x1, y1, x2, y2)

            // Adicionando o endereço logo abaixo da linha
            doc.text(endereco, 50, 290); // Define uma posição x menor para o alinhamento à esquerda

            // Mantém a paginação à direita
            doc.text(`Página ${i} de ${pageCount}`, 200, 290, { align: "right" });
        }
    }

    addPageNumbersPrescricao(doc) {
    const pageCount = doc.getNumberOfPages();
        const endereco = "Rua Projetada Interna, Nº 4, Araçagy, São José de Ribamar / MA, CEP: 65.125-253. Tel: (98) 3236-1737. www.saoluishomecare.com.br";
    
    // Para A4 em paisagem, a altura da página é aproximadamente 210mm.
    // Ajuste os valores de Y para que fiquem dentro do limite (por exemplo, 200 e 205).
    const yLine = 200;      // Posição Y para a linha
    const yText = 205;      // Posição Y para o endereço e paginação

    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setFont("helvetica", "normal");

        // Adiciona a linha (ajuste a largura e posição conforme necessário)
        doc.setDrawColor(57, 106, 159); // Cor azul
        doc.setLineWidth(1);
        doc.line(5, yLine, 292, yLine); // Linha horizontal; 292 ~ (297 - margem)

        // Adiciona o endereço à esquerda
        doc.text(endereco, 50, yText);

        // Adiciona a paginação à direita
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.text(`Página ${i} de ${pageCount}`, pageWidth - 10, yText, { align: "right" });
    }
}
    logomarcaGrande(doc) {
        try {
            doc.addImage(logo, 10, 8, 35, 32);
            doc.addImage(ona, 160, 15, 16, 18);
            doc.addImage(gptw, 185, 15, 16, 18);
        } catch (error) {
            console.error("Falha ao adicionar imagens ao cabeçalho:", error);
        }

    }
    logomarcaMedia(doc) {
        try {
            doc.addImage(logo, 10, 8, 30, 28);
            doc.addImage(ona, 160, 10, 15, 15);
            doc.addImage(gptw, 185, 10, 14, 15);
        } catch (error) {
            console.error("Falha ao adicionar imagens ao cabeçalho:", error);
        }

    }
    loadImageAsBase64(imageUrl) {
        var base64String = ''
        fetch(imageUrl, { cors: 'no-cors', method: 'GET' })
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onloadend = function () {
                    const base64String = reader.result;
                    console.log('String Base64:', base64String);
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => console.error('Erro ao converter para Base64:', error));
        return base64String
    }

    checkAndAddPage(doc, yPosition, lineHeight, maxHeight, newPosition, drawHeader) {
        if (yPosition + lineHeight > maxHeight) {
            doc.addPage();
            drawHeader(doc);
            yPosition = newPosition
            return yPosition;  // retorna o novo yPosition
        } else {
            this.contentAdded = true;
            return yPosition;  // retorna o yPosition inalterado
        }
    }

    joinURL(base, path) {
        return `${base.replace(/\/$/, '')}/${path.replace(/^\//, '')}`;
    }

    formatDate(value) {
        if (!value) return "";
        return moment(value).format("DD/MM/YYYY");
    }

    formatDateXML(value) {
        if (!value) return "";
        return moment(value).format("YYYY-MM-DD");
    }

    formatDateToFilename(value) {
        if (!value) return "";
        return moment(value).format("DD_MM_YYYY");
    }

    formatToBRL(number) {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(number);
    }

    formatToBRL2(value) {
        return value.toLocaleString(
            'pt-BR',
            {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        );
    }

    parseFormattedCurrency = (value) => {
        return parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.'));
    };
    formatCPF(cpf) {
        if (!cpf) return '';
        return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    }
}

export default new UtilsPDF();